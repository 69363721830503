<template>
  <section class="memoriam">
    <IconButton
      class="memoriam__sidebar-button button--md button--icon-white"
      @click="sidebarShow = true"
    >
      <IconMenu></IconMenu>
    </IconButton>
    <div class="memoriam__sidebar" :class="{ 'memoriam__sidebar--show': sidebarShow }">
      <div
        class="memoriam__category text-sub-title"
        :class="{ 'memoriam__category--active': item.active }"
        v-for="(item, index) in memoriams"
        :key="`memoriam-${index}`"
        @click="openMemoriam(item.key)"
      >
        <p class="memoriam__category-text text-sub-title">{{ item.title }}</p>
      </div>
      <IconButton
        class="memoriam__sidebar-close button--md button--outline-white"
        @click="sidebarShow = false"
      >
        <IconClose width="18" height="18"></IconClose>
      </IconButton>
    </div>
    <div class="memoriam__container">
      <div class="memoriam__content">
        <h2 class="loose-threads__item-title text-title" v-if="!loading && memoriams.length === 0">
          No Lives Well Lived
        </h2>
        <div class="memoriam__item" v-if="!loading && memoriams.length">
          <div class="memoriam__item-type memoriam__category text-sub-title">LIVES WELL LIVED</div>
          <h2 class="memoriam__item-title text-title">{{ memoriam.memoriam.title }}</h2>
          <p class="memoriam__item-date text-sub-title">
            {{ `${memoriam.memoriam.birth_year} - ${memoriam.memoriam.death_year}` }}
          </p>
          <div
            class="memoriam__item-description collect-editor collect-editor--white"
            v-html="memoriam.memoriam.description"
          ></div>
          <div class="memoriam__item-footer">
            <a
              class="memoriam__link memoriam__button"
              v-if="memoriam.memoriam.button_url"
              :href="memoriam.memoriam.button_url"
            >
              {{ `${memoriam.memoriam.button_text ? memoriam.memoriam.button_text : ""}` }}
            </a>
          </div>
        </div>
        <IconLoader
          v-else-if="loading"
          class="memoriam__loader loader--white loader--lg"
        ></IconLoader>
        <div v-else class="memoriam__empty"><h3>NO DATA</h3></div>
      </div>
      <div class="memoriam__nav">
        <div
          class="memoriam__nav-button"
          :class="{ 'memoriam__nav-button--disabled': !memoriam.next }"
          @click="openMemoriam(memoriam.next)"
        >
          <IconButton class="carousel__button carousel__button--back button--circle button--white">
            <IconArrowTop class="carousel__button-icon carousel__button-icon"></IconArrowTop>
          </IconButton>
          <span class="text-link">Previous</span>
        </div>
        <div
          class="memoriam__nav-button"
          :class="{ 'memoriam__nav-button--disabled': !memoriam.previous }"
          @click="openMemoriam(memoriam.previous)"
        >
          <span class="text-link">Next</span>
          <IconButton class="carousel__button carousel__button--next button--circle button--white">
            <IconArrowTop class="carousel__button-icon carousel__button-icon"></IconArrowTop>
          </IconButton>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import IconButton from "@/components/buttons/IconButton.vue";
import IconArrowTop from "@/components/icons/IconArrowTop.vue";
import IconLoader from "@/components/icons/IconLoader.vue";
import IconMenu from "@/components/icons/IconMenu.vue";
import IconClose from "@/components/icons/IconClose.vue";
import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
  components: {
    IconButton,
    IconArrowTop,
    IconLoader,
    IconMenu,
    IconClose,
  },
  data() {
    return {
      loading: false,
      sidebarShow: false,
    };
  },
  head: {
    title: function () {
      return {
        inner: "Lives Well Lived browngrotta arts",
      };
    },
    meta: function () {
      return [
        {
          name: "description",
          itemprop: "description",
          content: "Lives Well Lived browngrotta arts",
          id: "meta:description",
        },
        {
          itemprop: "name",
          content: "Lives Well Lived browngrotta arts",
          id: "meta:name",
        },
        {
          itemprop: "image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "meta:image",
        },
        {
          property: "og:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "og:url",
        },
        {
          property: "og:type",
          content: "website",
          id: "og:type",
        },
        {
          property: "og:title",
          content: "Lives Well Lived browngrotta arts",
          id: "og:title",
        },
        {
          property: "og:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "og:image",
        },
        {
          property: "og:image:alt",
          content: "Lives Well Lived browngrotta arts",
          id: "og:image:alt",
        },
        {
          property: "og:description",
          content: "Description Lives Well Lived browngrotta arts",
          id: "og:description",
        },
        {
          property: "og:site_name",
          content: process.env.VUE_APP_FRONTEND_URL,
          id: "og:site_name",
        },
        {
          property: "og:locale",
          content: "en_US",
          id: "og:locale",
        },
        {
          name: "twitter:card",
          content: "summary",
          id: "twitter:card",
        },
        {
          name: "twitter:site",
          content: "@BrowngrottaArts",
          id: "twitter:site",
        },
        {
          name: "twitter:creator",
          content: "@BrowngrottaArts",
          id: "twitter:creator",
        },
        {
          name: "twitter:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "twitter:url",
        },
        {
          name: "twitter:title",
          content: "Lives Well Lived browngrotta arts",
          id: "twitter:title",
        },
        {
          name: "twitter:description",
          content: "Description Lives Well Lived browngrotta arts",
          id: "twitter:description",
        },
        {
          name: "twitter:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "twitter:image",
        },
        {
          name: "twitter:image:alt",
          content: "Lives Well Lived browngrotta arts",
          id: "twitter:image:alt",
        },
      ];
    },
  },
  computed: {
    ...mapGetters("publicapi/memoriams", {
      memoriams: "getMemoriams",
      memoriam: "getMemoriam",
      activeLink: "getActiveLink",
    }),
  },
  async created() {
    this.loading = true;
    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Media",
        to: "/media",
        clickable: true,
      },
      {
        title: "Lives Well Lived",
        to: "/media/lives-well-lived",
        clickable: false,
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
    await this.apiMemoriams();
    if (this.memoriams.length > 0) {
      let activeLink = this.activeLink || this.memoriams[0].key;
      await this.apiMemoriam(activeLink);
      this.setActiveLink(activeLink);
      this.setMemoriamsActive(activeLink);
    }
    this.loading = false;
  },
  destroyed() {
    this.setActiveLink(false);
  },
  methods: {
    ...mapMutations(["setBreadcrumbs"]),
    ...mapActions("publicapi/memoriams", ["apiMemoriams", "apiMemoriam"]),
    ...mapMutations("publicapi/memoriams", ["setMemoriamsActive", "setActiveLink"]),
    async openMemoriam(slug) {
      this.loading = true;
      this.setMemoriamsActive(slug);
      this.setActiveLink(slug);
      await this.apiMemoriam(slug);
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.memoriam {
  padding: 21rem 10.5rem 14.5rem;
  min-height: 100vh;
  position: relative;
  @media screen and (max-width: $md) {
    padding: 21rem 5rem 14.5rem;
  }
  @media screen and (max-width: $xs) {
    padding: 21rem 25px 14.5rem;
  }
  display: flex;
  &__sidebar {
    padding-right: 10px;
    min-width: 31.5rem;
    max-width: 25vw;
    flex-shrink: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 78.6rem;
    @media screen and (max-width: $md) {
      min-width: 28rem;
    }
    @media screen and (max-width: $sm) {
      position: absolute;
      right: 0;
      height: 100%;
      max-height: calc(100% - 35.5rem);
      max-width: 100%;
      background-color: $lightBlack;
      z-index: 1;
      padding: 10px 80px 10px 25px;
      transition: all 0.5s ease;
      transform: translateX(100%);
    }
    &--show {
      @media screen and (max-width: $sm) {
        transform: translateX(0);
      }
    }
    & > .memoriam__category {
      cursor: pointer;
    }
    &-button {
      display: none;
      position: absolute;
      right: 25px;
      z-index: 1;
      border: none;
      border-radius: 50%;
      background-color: transparent;
      &:hover {
        background-color: rgba($color: $white, $alpha: 0.2);
      }
      @media screen and (max-width: $sm) {
        display: block;
      }
    }
    &-close {
      display: none;
      position: absolute;
      top: 10px;
      right: 25px;
      @media screen and (max-width: $sm) {
        display: block;
      }
    }
  }
  &__category {
    width: max-content;
    max-width: 100%;
    padding: 5px 10px;
    border: 1px solid $lightGrey;
    border-radius: 19px;
    transition: all 0.3s ease;
    margin-bottom: 1.7rem;
    &-text {
      font-size: 1.6rem;
      line-height: 1.8;
      text-transform: uppercase;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      line-clamp: 1;
      text-overflow: clip;
      overflow: hidden;
    }
    &:hover {
      background-color: rgba($color: $white, $alpha: 0.3);
    }
    &--active {
      background-color: $white;
      .memoriam__category-text {
        color: $black;
      }
      &:hover {
        background-color: $lightGrey;
      }
    }
  }
  &__container {
    padding-left: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  &__nav {
    display: flex;
    justify-content: space-between;
    &-button {
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        margin: 0 15px;
      }
      &--disabled {
        pointer-events: none;
        opacity: 0.6;
      }
    }
  }
  &__content {
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: 4rem;
    overflow-y: scroll;
  }
  &__item {
    width: 100%;
    &-type {
      margin-bottom: 5px;
    }
    &-title {
      font-size: 4rem;
      line-height: 1.25;
      margin-bottom: 5px;
    }
    &-date {
      margin-bottom: 30px;
    }
    &-description {
      &.collect-editor ::v-deep * {
        font-family: $fontFrutigerNeue;
      }
      &.collect-editor ::v-deep p {
        font-weight: 300;
      }
    }
    &-footer {
      display: flex;
      justify-content: flex-end;
    }
  }
  &__link {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: $white;
    color: $black;
    font-family: $fontFrutiger;
    height: 5.2rem;
    padding: 0 24px;
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.5;
    text-decoration: none;
    margin-top: 20px;
    @media (max-width: $xxs) {
      padding: 0 12px;
    }
    @media (max-width: $xxxs) {
      font-size: 1.6rem;
    }
    &:hover {
      background-color: $light2Grey;
    }
  }
  &__button {
    width: max-content;
    min-width: 16rem;
  }
  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    h3 {
      font-size: 20px;
      font-weight: 600;
    }
  }
}

.carousel {
  &-wrapper {
    position: relative;
    margin-bottom: 10px;
  }
  &__button {
    width: 4.6rem;
    height: 4.6rem;
    @media screen and (max-width: $xs) {
      width: 4.2rem;
      height: 4.2rem;
    }
    &--next {
      transform: rotate(90deg);
    }
    &--back {
      transform: rotate(-90deg);
    }
    & &-icon {
      width: 18px;
      height: 12px;
      @media screen and (max-width: $xs) {
        width: 14px;
        height: 8px;
      }
    }
  }
}
</style>
